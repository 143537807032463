import React from "react";
import PropTypes from "prop-types";

const Meta = props => {
  const { prefix, readingTime } = props;

  return (
    <p className="meta">
      <span className="data">{prefix}</span>
      <span className="dot" />
      <span className="reading-time">{readingTime.text}</span>

      {/* --- STYLES --- */}
      <style jsx>{`
        .meta {
          display: flex;
          align-items: center;
          background: transparent;
          font-family: Steradian;
          font-style: normal;
          font-weight: normal;
          line-height: 1.4;
          letter-spacing: -0.01em;
          color: #a9a9bf;

          .dot {
            margin: 0 16px;
            width: 3px;
            height: 3px;
            background: #a9a9bf;
            border-radius: 10px;
          }
        }
        @media screen and (min-width: 320px) {
          .meta {
            margin: 18px 0 20px;
            font-size: 14px;
          }
        }
        @media screen and (min-width: 1080px) {
          .meta {
            margin: 28px 0 40px;
            font-size: 18px;
          }
        }
      `}</style>
    </p>
  );
};

Meta.propTypes = {
  prefix: PropTypes.string.isRequired,
  category: PropTypes.array,
  readingTime: PropTypes.object
};

export default Meta;
