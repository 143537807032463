import React from "react";
import PropTypes from "prop-types";

import Meta from "./Meta";
import { NextPosts } from "./NextPosts";
import Headline from "../Article/Headline";
import Bodytext from "../Article/Bodytext";
import { ContactSectionContent } from "../../sections/ContactSection/ContactSectionContent";

const Post = props => {
  const {
    next,
    post: {
      html,
      fields: { readingTime },
      frontmatter: { title, date }
    }
  } = props;

  console.info(next);

  return (
    <React.Fragment>
      <div className="post-width">
        <header>
          <Headline title={title} />
          <Meta prefix={date} readingTime={readingTime} />
        </header>

        <Bodytext html={html} />
      </div>

      {next.length && (
        <footer>
          <div className="posts-container">
            <h3 className="read-more">Read more</h3>
            <NextPosts next={next} />
          </div>

          <div className="contact-form">
            <ContactSectionContent />
          </div>
        </footer>
      )}

      <style jsx>{`
        .read-more {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.3;
          letter-spacing: -0.02em;
          color: #141418;
        }

        @media screen and (min-width: 320px) {
          .post-width {
            margin: 0 auto 40px;
            padding: 0 20px 0 20px;
          }
          .read-more {
            font-size: 28px;
            margin-bottom: 20px;
          }
          .contact-form {
            margin-bottom: 50px;
            padding: 0 20px;
          }
          :global(.blog-posts-wrap) {
            margin-bottom: 80px;
          }
          .posts-container {
            padding: 0 20px 0 20px;
          }
        }
        @media screen and (min-width: 1080px) {
          .post-width {
            margin: 0 auto 80px;
            padding: 0 190px 0 220px;
          }
          .read-more {
            font-size: 36px;
            margin-bottom: 40px;
          }
          .contact-form {
            margin-bottom: 150px;
            padding: 0 70px;
          }
          :global(.blog-posts-wrap) {
            margin-bottom: 200px;
          }
          .posts-container {
            padding: 0 70px 0 160px;
          }
        }
        @media screen and (min-width: 1300px) {
          .contact-form {
            padding: 0 130px;
          }
          .posts-container {
            padding: 0 130px 0 220px;
          }
          .post-width {
            padding: 0 260px 0 280px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
  next: PropTypes.array
};

export default Post;
