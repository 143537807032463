import React from "react";
import PropTypes from "prop-types";

const Article = props => {
  const { children } = props;

  return (
    <React.Fragment>
      <article className="article">{children}</article>

      {/* --- STYLES --- */}
      <style jsx>{`
        @media screen and (min-width: 320px) {
          .article {
            margin: 45px auto 0;
          }
        }
        @media screen and (min-width: 1080px) {
          .article {
            margin: 148px auto 0;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Article.propTypes = {
  children: PropTypes.node.isRequired
};

export default Article;
