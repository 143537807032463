import React from "react";
import { BlogItem } from "../../sections/BlogSection/BlogItem";
import PropTypes from "prop-types";

const NextPosts = ({ next }) => {
  const postsItems = () => next && next.length && next.map((item, key) => <BlogItem post={item.node} key={key} />);

  return <ul className="blog-posts-wrap">{postsItems()}</ul>;
};

NextPosts.propTypes = {
  next: PropTypes.array
};

export { NextPosts };
