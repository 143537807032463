import React from "react";
import PropTypes from "prop-types";

const Bodytext = props => {
  const { html } = props;

  return (
    <React.Fragment>
      <div className="bodytext" dangerouslySetInnerHTML={{ __html: html }} />

      <style jsx>{`
        .bodytext {
          animation-name: bodytextEntry;
          animation-duration: 1s;

          :global(h2),
          :global(h3) {
            margin: 1.5em 0 1em;
          }

          :global(h2) {
            font-family: Neutrif Pro;
            font-style: normal;
            font-weight: 500;
            line-height: 1.1;
            letter-spacing: -0.02em;
            color: #141418;
          }

          :global(h3) {
            font-family: Neutrif Pro;
            font-style: normal;
            font-weight: 500;
            line-height: 1.1;
            letter-spacing: -0.02em;
            color: #141418;
          }

          :global(p) {
            font-family: Neutrif Pro;
            font-style: normal;
            font-weight: normal;
            line-height: 1.4;
            color: #141418;
          }

          :global(ul) {
            list-style: circle;
            margin: 0 0 1.5em;
            padding: 0 0 0 1.5em;
          }

          :global(li) {
            margin: 0.7em 0;
            line-height: 1.5;
          }

          :global(a) {
            font-weight: 600;
            color: #598cde;
            text-decoration: underline;
          }

          :global(.gatsby-resp-image-wrapper) {
            max-width: 100% !important;
          }
        }

        @keyframes bodytextEntry {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @media screen and (min-width: 320px) {
          .bodytext {
            :global(h2) {
              font-size: 28px;
            }
            :global(h3) {
              font-size: 18px;
            }
            :global(p) {
              font-size: 16px;
              margin-bottom: 14px;
            }
            :global(.gatsby-resp-image-link) {
              margin-bottom: 20px;
            }
          }
        }
        @media screen and (min-width: 1080px) {
          .bodytext {
            :global(h2) {
              font-size: 38px;
            }
            :global(h3) {
              font-size: 24px;
            }
            :global(p) {
              font-size: 18px;
              margin-bottom: 24px;
            }
            :global(.gatsby-resp-image-link) {
              margin-bottom: 40px;
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Bodytext.propTypes = {
  html: PropTypes.string.isRequired
};

export default Bodytext;
