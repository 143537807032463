import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import Arrowleft from "../../images/svg-icons/arrow-left.svg";

const Headline = props => {
  const { title, children } = props;

  const handleClick = () => navigate(-1);

  return (
    <React.Fragment>
      <button className="back-link" onClick={handleClick}>
        <Arrowleft className="arrow-icon" />
        <span className="text">blog</span>
      </button>

      {title ? <h1>{title}</h1> : <h1>{children}</h1>}

      {/* --- STYLES --- */}
      <style jsx>{`
        h1 {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: 500;
          line-height: 1.1;
          letter-spacing: -0.02em;
          color: #141418;
          animation-name: headlineEntry;
          animation-duration: 1s;
        }

        :global(.back-link) {
          font-family: Neutrif Pro;
          font-style: italic;
          font-weight: normal;
          font-size: 20px;
          line-height: 1.1;
          color: #0005fb;
          background: transparent;
          cursor: pointer;
          text-transform: uppercase;
          margin-bottom: 40px;
          display: block;
        }

        :global(.arrow-icon) {
          margin-right: 16px;
        }

        @keyframes headlineEntry {
          from {
            opacity: 0.5;
          }
          to {
            opacity: 1;
          }
        }

        @media screen and (min-width: 320px) {
          :global(.back-link) {
            font-size: 14px;
          }
        }
        @media screen and (min-width: 768px) {
          h1 {
            font-size: 32px;
          }
        }

        @media screen and (max-width: 1080px) {
          :global(.arrow-icon) {
            width: 28px;
          }
        }
        @media screen and (min-width: 1080px) {
          h1 {
            font-size: 64px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Headline.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default Headline;
