module.exports = {
  siteTitle: "techflower",
  shortSiteTitle: "techflower - remote software development teams", // <title> ending for posts and pages
  siteDescription: "R&D and software remote teams",
  siteUrl: "https://techflower.io",
  pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Alex Morozov",
  authorEmail: "hello@techflower.io",
  authorTwitterAccount: "",

  /* info */
  headerTitle: "techflower",
  headerSubTitle: "On-demand Software Dev Teams",

  /* manifest.json */
  manifestName: "techflower.io Software Teams",
  manifestShortName: "techflower", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  gravatarImgMd5: "",
  authorSocialLinks: [
    { facabook: "https://www.facebook.com/techflower.ukraine" },
    { Linkedin: "https://www.linkedin.com/company/techflower-ukraine/" }
  ]
};
